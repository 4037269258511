import { useEffect, useState } from 'react'
import { Product } from '../../../components/productCard/data';
import { decode } from 'html-entities';
import { sortProducts, sortProductsByStock } from '../Equipment';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ButtonComponent, ContainerComponent, ParagraphComponent, ProductCardComponent, ProductCardSkeletonComponent, ProductModalComponent, SubtitleComponent, TitleComponent } from '../../../components';
import { Link } from 'react-router-dom';
import useProductStore, { findProductById } from '../../../store/productStore';
import { useParams } from 'react-router';


function Inverters() {
    // const [inverters, setInverters] = useState([]);
    const { inverters, loading, fetchInverters } = useProductStore();
    const [currentPage, setCurrentPage] = useState(1)
    const [tempSolarProducts, setTempSolarProducts] = useState<Product[]>([]);
    // const [solarProducts, setSolarProducts] = useState<Product[]>([]);
    // const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const { id } = useParams();
    const [openProduct, setOpenProduct] = useState<Product>()


    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     if (id && tempSolarProducts.length > 0) {
    //         setOpenProduct(findProductById(inverters.products, id))
    //         setOpenModal(true)
    //     }
    // }, [id, tempSolarProducts])

    useEffect(() => {
        if (inverters.products.length === 0) fetchInverters(0)
    }, [])

    useEffect(() => {
        // setTempSolarProducts(inverters)
        const sortedProducts = sortProducts(inverters.products);
        setTempSolarProducts(sortedProducts);
    }, [inverters]);

    return (
        <>
            {/* <ProductModalComponent openModal={openModal} closeModal={() => setOpenModal(false)} selectedProduct={openProduct} singleProductDisplay={true} /> */}
            <div className="equipment" id="equipmentContainer">
                <Helmet>
                    <title>{t('solarEquipment.meta3')}</title>
                    <meta name="description" content={t('solarEquipment.meta.description')} />
                </Helmet>
                <ContainerComponent>
                    <h1><TitleComponent centered smaller style={{ marginTop: '3rem' }}>{t('solarEquipment.title3')}</TitleComponent></h1>
                    <SubtitleComponent centered>{t('solarEquipment.subtitle')}</SubtitleComponent>
                    {loading ? <div className="productCardContainer">
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                    </div> : <>
                        <div className="productCardContainer">
                            <ProductCardComponent chargers={false} data={tempSolarProducts} />
                        </div>
                    </>}
                    {inverters.pagination.page_count && currentPage <= inverters.pagination.page_count - 1 ?
                        <ButtonComponent style={{ margin: '0 auto', display: 'block' }} onClick={() => { setCurrentPage(currentPage + 1); fetchInverters(currentPage) }}>Load more</ButtonComponent>
                        : ''}
                    {inverters.pagination.total ? <ParagraphComponent style={{ color: 'white' }}>{tempSolarProducts.length} of {inverters.pagination.total} Products</ParagraphComponent> : ''}

                </ContainerComponent>
            </div>
        </>
    )
}

export default Inverters