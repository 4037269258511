import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { ContainerComponent, ImageSwiper, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components';
import './diagnostics.style.scss'


import measureImage from '../../assets/images/diagnostics/measure.jpeg'

import diagnostics1 from '../../assets/images/diagnostics/diagnostics1.jpg'

import diagnostics2 from '../../assets/images/diagnostics/diagnostics2.jpg'

import diagnostics3 from '../../assets/images/diagnostics/diagnostics3.jpg'

import diagnostics4 from '../../assets/images/diagnostics/diagnostics4.jpg'

import diagnostics5 from '../../assets/images/diagnostics/diagnostics5.jpg'

import diagnostics6 from '../../assets/images/diagnostics/diagnostics6.jpg'

import diagnostics7 from '../../assets/images/diagnostics/diagnostics7.jpg'

function Diagnostics({ t }: { t: any }) {
    console.log(typeof measureImage);

    const captions = t("diagnostics.captions", { returnObjects: true });

    return (
        <div className='diagnostics'>
            <Helmet>
                <title>{t('diagnostics.meta.title')}</title>
                <meta name="description" content={t('diagnostics.meta.description')} />
            </Helmet>
            <ContainerComponent>
                <h1><TitleComponent centered smaller>{t('diagnostics.title')}</TitleComponent></h1>
                <div className="diagnosticsIntroText">
                    <h2><SubtitleComponent max>{t('diagnostics.subtitle1')}<span><a style={{ margin: 0 }} href='mailto:info@tesenergy.hr'> info@tesenergy.hr</a></span>{t('diagnostics.subtitle2')}</SubtitleComponent></h2>
                </div>
                <img className='diagnostics-image' src={measureImage} alt={t('software.landingSection.paragraph2')} />

                <h2><TitleComponent centered moreSmaller>{t('diagnostics.diagnostics')}</TitleComponent></h2>
                <h3><SubtitleComponent>{t('diagnostics.diagnosticsSubtitle')}</SubtitleComponent></h3>
                <ImageSwiper images={[diagnostics1, diagnostics2, diagnostics3, diagnostics4, measureImage, diagnostics5, diagnostics6]} captions={captions} thumbs={true} />
                <h2><TitleComponent centered moreSmaller>{t('diagnostics.supervision')}</TitleComponent></h2>
                <h3><SubtitleComponent>{t('diagnostics.supervisionSubtitle')}</SubtitleComponent></h3>
                <SubtitleComponent>{t('diagnostics.supervisionPoints.title')}</SubtitleComponent>
                <div className="points">
                    <ul>
                        <li><h4><SubtitleComponent>{t('diagnostics.supervisionPoints.p1')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.supervisionPoints.p3')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.supervisionPoints.p4')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.supervisionPoints.p5')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.supervisionPoints.p2')}</SubtitleComponent></h4></li>
                    </ul>
                </div>

                <img className='diagnostics-image' src={diagnostics7} alt="Helmet on solar panel" />

                <h2><TitleComponent centered moreSmaller>{t('diagnostics.maintenance')}</TitleComponent></h2>
                <SubtitleComponent>{t('diagnostics.maintenanceSubtitle')}</SubtitleComponent>
                <div className="points">
                    <ul>
                        <li><h4><SubtitleComponent>{t('diagnostics.maintenancePoints.p1')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.maintenancePoints.p2')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.maintenancePoints.p3')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.maintenancePoints.p4')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.maintenancePoints.p5')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.maintenancePoints.p6')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.maintenancePoints.p7')}</SubtitleComponent></h4></li>
                        <li><h4><SubtitleComponent>{t('diagnostics.maintenancePoints.p8')}</SubtitleComponent></h4></li>
                    </ul>
                </div>
            </ContainerComponent>
        </div>
    )
}

export default withTranslation()(Diagnostics);
